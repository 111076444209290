nav {
    width: 100%;
    background: #1D2948;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    position: fixed;
    top: 0; 
    z-index: 100;
    padding-top: 10px;
}

nav .logo img {
    height: 100%;
    max-width: 100%;
}

nav ul {
    list-style: none;
}

nav ul li {
    display: inline-block;
    margin-right: 20px;
}

nav ul li a {
    font-size: 16px;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
}

nav .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10%;
}

nav .icons a {
    margin-left: 10%;
    
}

nav .icons a img {
    display:inline-block;
    height: 25px;
    width: 30px;
    top:auto;
    margin-right: 2%;
}

  .dropdown {
    position: relative;
    display:contents;

}

.dropdown-button {
    background-color: #1D2948;
    padding: 3px 5px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    
    display:none;
    position: absolute;
    min-width: 0px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    background-color: #161922;
    margin-top: 1.5%;
    width: 15%;
}


.dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display:column;
    color:#1D2948;
    color: white;
}

.dropdown-item:hover {
    background-color:#1D2948;
}

.dropdown:hover .dropdown-content {
    display:flex;
    flex-direction: column;
}

.show-dropdown-content{
    display:flex;
    flex-direction: column;
}


.buttons-container{
    display: flex;
    margin-right: 10%;
}

.wallet-button{
    background: linear-gradient(#161922, #161922) padding-box, linear-gradient(60deg, #40AFFD, #AB2FFE) border-box;
    color: #313149;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 1%;
    width: 140px;
    padding-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
    /* margin-right: 10%; */
}

.wallet-image{
    width: 20px;
    height: 20px;
    margin-left: 4%;
    margin-bottom: 2%;
}

@media screen and (max-width: 836px) {
    nav{
        flex-direction: column;
        padding-bottom: 10px;
    }
    .dropdown-content{
        width: 100%;
    }
  }


  @media screen and (max-width: 473px) {
    .buttons-container{
        justify-content: center;
    }
  }

  @media screen and (max-width:343px) {
    .logo{
        scale: 0.65;
    }
  }